"use client";

import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import * as Primitive from "@radix-ui/react-tooltip";

export function Tooltip(props: Primitive.TooltipProps) {
  return <Primitive.Root {...props} />;
}

Tooltip.Provider = Primitive.Provider;
Tooltip.Trigger = Primitive.Trigger;

const Content = forwardRef<
  React.ElementRef<typeof Primitive.Content>,
  React.ComponentPropsWithoutRef<typeof Primitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Primitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={twMerge(
      "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md border border-gray-300 bg-gray-700  px-5 py-3 font-diablo font-normal text-gray-100 shadow-md",
      className,
    )}
    {...props}
  />
));

Content.displayName = Primitive.Content.displayName;
Tooltip.Content = Content;
